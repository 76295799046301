export function hideElement(element) {
  element.classList.remove('d-block');
  element.classList.add('d-none');
}

export function showElement(element) {
  element.classList.remove('d-none');
  element.classList.add('d-block');
}

export function hideAriaElement(element) {
  element.setAttribute('aria-hidden', 'true');
}

export function showAriaElement(element) {
  element.setAttribute('aria-hidden', 'false');
}
