import { accounting } from '@rasgo/accounting';
import BaseController from './base';

export default class TaxLiabilitiesController extends BaseController {
  static targets = ['totalTaxLiability', 'liabilitySelect'];
  static values = {
    allLiabilities: Array,
  };

  connect() {
    this.calculateTotalTaxLiability();
  }

  updateTotalLiabilityAmount(event) {
    this.calculateTotalTaxLiability();
  }
  calculateTotalTaxLiability() {
    let totalTaxLiability = 0;
    this.liabilitySelectTargets.forEach((selectTarget) => {
      const externalPayrollUuid = selectTarget.value;
      const tax_id = parseInt(selectTarget.dataset.taxLiabilitiesTaxIdParam);
      const selectedLiability = this.allLiabilitiesValue.find(
        (liability) =>
          liability.tax_id === tax_id && liability.external_payroll_uuid === externalPayrollUuid,
      );

      if (selectedLiability) {
        totalTaxLiability += parseFloat(selectedLiability.liability_amount);
      }
    });
    this.setTotalTaxLiability(totalTaxLiability);
  }

  setTotalTaxLiability(totalTaxLiability) {
    this.totalTaxLiabilityTarget.innerText = accounting.formatMoney(totalTaxLiability);
  }
}
