import IMask from 'imask';

window.IMask = IMask;

window.MaskUtils = {
  DEFAULT_CURRENCY_OPTIONS: Object.freeze({
    mask: Number,
    scale: 2,
    signed: false,
    max: 999999999,
    radix: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
  }),
  maskCurrency: function (elem) {
    IMask(elem, window.MaskUtils.DEFAULT_CURRENCY_OPTIONS);
  },
  DEFAULT_HOURS_OPTIONS: Object.freeze({
    mask: Number,
    scale: 3,
    signed: false,
    max: 20000,
    radix: '.',
    padFractionalZeros: false,
    normalizeZeros: true,
  }),
  maskHours: function (elem) {
    IMask(elem, window.MaskUtils.DEFAULT_HOURS_OPTIONS);
  },
};

// For more detail, refer to doc at https://imask.js.org/guide.html#masked-pattern
document.addEventListener('DOMContentLoaded', () => {
  var maskedInputs = document.querySelectorAll('[data-mask]');
  if (maskedInputs.length) {
    maskedInputs.forEach((elem) => {
      var hidePlaceholder = elem.getAttribute('data-hide-placeholder') === 'true';
      IMask(elem, {
        mask: elem.getAttribute('data-mask'),
        lazy: hidePlaceholder,
      });
    });
  }

  var ssnInputs = document.querySelectorAll('[data-mask-ssn]');
  if (ssnInputs.length) {
    ssnInputs.forEach((elem) => {
      IMask(elem, {
        mask: [
          { mask: '000-00-0000', lazy: false },
          { mask: '\\*\\*\\*-\\*\\*-\\*\\*\\*\\*', lazy: false },
        ],
      });
    });
  }

  var einInputs = document.querySelectorAll('[data-mask-ein]');
  if (einInputs.length) {
    einInputs.forEach((elem) => {
      IMask(elem, {
        mask: [
          { mask: '00-0000000', lazy: false },
          { mask: '\\*\\*-\\*\\*\\*\\*\\*\\*\\*', lazy: false },
        ],
      });
    });
  }

  var routingNumberInputs = document.querySelectorAll('[data-mask-routing-number]');
  if (routingNumberInputs.length) {
    routingNumberInputs.forEach((elem) => {
      IMask(elem, {
        mask: [{ mask: /^[0-9]+$/, lazy: false, placeholderChar: '' }],
      });
    });
  }

  var BankAccountNumberInputs = document.querySelectorAll('[data-mask-bank-account-number]');
  if (BankAccountNumberInputs.length) {
    BankAccountNumberInputs.forEach((elem) => {
      IMask(elem, {
        mask: [{ mask: /^[X]*[0-9]*$/gm, lazy: false, placeholderChar: '' }],
      });
    });
  }

  var currencyInputs = document.querySelectorAll('[data-mask-currency]');
  if (currencyInputs.length) {
    currencyInputs.forEach(window.MaskUtils.maskCurrency);
  }

  var hoursInputs = document.querySelectorAll('[data-mask-hours]');
  if (hoursInputs.length) {
    hoursInputs.forEach(window.MaskUtils.maskHours);
  }

  var hexcolorInputs = document.querySelectorAll('[data-mask-hexcolor');
  if (hexcolorInputs.length) {
    hexcolorInputs.forEach((elem) => {
      IMask(elem, {
        mask: [{ mask: /^#[0-9a-f]{0,6}$/i, lazy: false }],
      });
    });
  }
});
