import BaseController from './base';

const DISPLAY_NONE_CLASS = 'd-none';

export default class extends BaseController {
  declare readonly inputTarget: HTMLInputElement;
  declare readonly elementTarget: HTMLElement;

  declare showOnValue: string;
  declare readonly hasShowOnValue: boolean;
  declare readonly hasElementTarget: boolean;
  declare readonly hasInputTarget: boolean;

  static targets = ['input', 'element'];
  static values = { showOn: String };

  connect() {
    this.toggle();
  }

  toggle() {
    const configured = this.hasShowOnValue && this.hasInputTarget && this.hasElementTarget;
    if (!configured) {
      return;
    }

    const classList = this.elementTarget.classList;
    this.inputTarget.value === this.showOnValue
      ? classList.remove(DISPLAY_NONE_CLASS)
      : classList.add(DISPLAY_NONE_CLASS);
  }
}
