import type axe from 'axe-core';
import BaseController from './base';

const HIDDEN_CLASS = 'd-none';

const DEFAULT_ICON = 'bi bi-person-arms-up';

const ISSUES_FOUND_LABEL = '[A11y] Issues found';
const ISSUES_FOUND_ICON_CLASSES = 'bi bi-person-fill-exclamation';

const NO_ISSUES_FOUND_LABEL = '[A11y] No issues';
const NO_ISSUES_ICON_CLASSES = 'bi bi-person-fill-check';

declare global {
  interface Window {
    AccessibilityAuditor: AccessibilityAuditor;
  }
}

class AccessibilityAuditor extends BaseController {
  declare pendingRun: boolean;

  declare readonly labelTarget: HTMLSpanElement;
  declare readonly issueTemplateRowTarget: HTMLTemplateElement;
  declare readonly iconTarget: HTMLElement;
  declare readonly panelTarget: HTMLDivElement;
  declare readonly engineTarget: HTMLSpanElement;
  declare readonly rulesTarget: HTMLSpanElement;
  declare readonly auditOnConnectValue: boolean;

  declare issuesListTarget: HTMLUListElement;
  declare results: axe.AxeResults;
  declare axe: typeof axe;

  static targets = ['label', 'icon', 'panel', 'issueTemplateRow', 'issuesList', 'engine', 'rules'];
  static values = { auditOnConnect: Boolean };

  initialize() {
    window.AccessibilityAuditor = this;
  }

  async connect() {
    const { default: axe } = await import('axe-core');
    this.axe = axe;

    if (this.auditOnConnectValue) {
      window.requestIdleCallback(() => this.audit(), { timeout: 2000 });
    }
  }

  async audit() {
    if (this.pendingRun) {
      return;
    }

    this.labelTarget.innerHTML = 'Running audit';
    this.iconTarget.className = DEFAULT_ICON;
    this.pendingRun = true;
    this.results = await this.axe.run({ runOnly: ['wcag22aa', 'best-practice'] });
    this.pendingRun = false;

    this.updateUI();
  }

  updateUI() {
    if (this.results.violations.length) {
      this.labelTarget.innerText = ISSUES_FOUND_LABEL;
      this.iconTarget.className = ISSUES_FOUND_ICON_CLASSES;

      this.issuesListTarget.innerHTML = '';
      this.results.violations.forEach((issue) => {
        const row = (this.issueTemplateRowTarget.content.cloneNode(true) as DocumentFragment)
          .firstElementChild as HTMLLIElement;

        row.dataset.accessibilityAuditorIssueSourceValue = JSON.stringify(issue);
        this.issuesListTarget.append(row);
      });
    } else {
      this.labelTarget.innerText = NO_ISSUES_FOUND_LABEL;
      this.iconTarget.className = NO_ISSUES_ICON_CLASSES;
    }

    this.engineTarget.innerText = `${this.results.testEngine.name} ${this.results.testEngine.version}`;
    this.rulesTarget.innerText = (this.results.toolOptions.runOnly as axe.RunOnly).values.join(
      ', ',
    );
  }

  togglePanel() {
    this.panelTarget.classList.toggle(HIDDEN_CLASS);
  }

  openPanel() {
    this.panelTarget.classList.remove(HIDDEN_CLASS);
  }

  closePanel() {
    this.panelTarget.classList.add(HIDDEN_CLASS);
  }
}

export default AccessibilityAuditor;
