import { showElement, hideElement } from '@/common/toggleElement';
/*
TODO: fix file to improve fetch/promise functions
*/

const TWICE_A_MONTH_CUSTOM = 'Twice a month: Custom';

/* eslint-disable promise/catch-or-return, promise/always-return */
document.addEventListener('DOMContentLoaded', () => {
  var payFrequencyInput = document.getElementById('pay_schedule_frequency');
  var payFrequencyValue = payFrequencyInput?.value;

  let firstDayOfMonth = document.getElementsByClassName('pay_schedule_day_1')[0];
  let secondDayOfMonth = document.getElementsByClassName('pay_schedule_day_2')[0];
  let firstDayOfMonthInput = document.getElementById('pay_schedule_day_1');
  let secondDayOfMonthInput = document.getElementById('pay_schedule_day_2');
  let firstDayOfMonthValue = firstDayOfMonthInput?.value;
  let secondDayOfMonthValue = secondDayOfMonthInput?.value;

  var payScheduleAnchorDateInput = document.getElementById('pay_schedule_anchor_pay_date');
  var deadlineToRunPayrolls = document.querySelectorAll(
    '[name="pay_schedule[deadline_to_run_payroll]"]',
  );
  var payScheduleAnchorEndOfPayPeriodInput = document.getElementById(
    'pay_schedule_anchor_end_of_pay_period',
  );
  var payScheduleAnchorEndOfPayPeriodValue = payScheduleAnchorEndOfPayPeriodInput?.value;
  let transitionPayrollAlert = document.getElementById('transition-payroll-alert');

  function fetchAndSetDeadlineToRunPayroll() {
    var deadlineInput = document.querySelector('[name="pay_schedule[deadline]"]');
    if (
      deadlineInput &&
      payFrequencyInput.value !== '' &&
      payScheduleAnchorDateInput.value !== ''
    ) {
      var url = deadlineInput.dataset.url;
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          pay_schedule: {
            frequency: payFrequencyInput.value,
            day_1: firstDayOfMonthInput.value,
            day_2: secondDayOfMonthInput.value,
            anchor_pay_date: payScheduleAnchorDateInput.value,
          },
        }),
      })
        .then((res) => {
          if (res.status == 200) {
            return res.json();
          }
        })
        .then((body) => {
          if (body !== undefined) {
            deadlineToRunPayrolls.forEach((el) => (el.value = body.deadline));
          } else {
            deadlineToRunPayrolls.forEach((el) => (el.value = ''));
          }
        });
    }
  }

  function displayDaysOfMonth() {
    if (payFrequencyInput.value === TWICE_A_MONTH_CUSTOM) {
      showElement(firstDayOfMonth);
      showElement(secondDayOfMonth);
    } else {
      firstDayOfMonthInput.value = '';
      secondDayOfMonthInput.value = '';
      hideElement(firstDayOfMonth);
      hideElement(secondDayOfMonth);
    }
  }

  // Transition payroll alert
  function displayTransitionPayrollAlert() {
    let dayOfMonthValueChanged =
      payFrequencyInput.value === TWICE_A_MONTH_CUSTOM &&
      (firstDayOfMonthInput.value != firstDayOfMonthValue ||
        secondDayOfMonthInput.value != secondDayOfMonthValue);
    if (
      payScheduleAnchorEndOfPayPeriodValue == '' ||
      (payFrequencyInput.value == payFrequencyValue &&
        !dayOfMonthValueChanged &&
        payScheduleAnchorEndOfPayPeriodInput.value == payScheduleAnchorEndOfPayPeriodValue)
    ) {
      hideElement(transitionPayrollAlert);
    } else {
      showElement(transitionPayrollAlert);
    }
  }

  payFrequencyInput?.addEventListener('change', displayTransitionPayrollAlert);
  firstDayOfMonthInput?.addEventListener('change', displayTransitionPayrollAlert);
  secondDayOfMonthInput?.addEventListener('change', displayTransitionPayrollAlert);
  payScheduleAnchorEndOfPayPeriodInput?.addEventListener('change', displayTransitionPayrollAlert);

  // Deadline to run payroll
  if (payScheduleAnchorDateInput && payFrequencyInput) {
    fetchAndSetDeadlineToRunPayroll();

    payScheduleAnchorDateInput.addEventListener('focusout', fetchAndSetDeadlineToRunPayroll);
    payFrequencyInput.addEventListener('change', fetchAndSetDeadlineToRunPayroll);
    firstDayOfMonthInput.addEventListener('change', fetchAndSetDeadlineToRunPayroll);
    secondDayOfMonthInput.addEventListener('change', fetchAndSetDeadlineToRunPayroll);
  }

  if (payFrequencyInput && firstDayOfMonthInput && secondDayOfMonthInput) {
    displayDaysOfMonth();

    payFrequencyInput.addEventListener('change', () => {
      displayDaysOfMonth();
    });
  }
});
