/*
TODO: fix file to improve fetch/promise functions
*/
/* eslint-disable promise/catch-or-return, promise/always-return, promise/no-nesting */
import { reEnableDisabledElements } from '@/common/disable-form-submissions';

function pollPayrollStatus(url) {
  setTimeout(() => {
    fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((resp) => resp.json())
      .then((json) => {
        if (json.complete) {
          window.location = json.redirect_url;
        } else {
          pollPayrollStatus(url);
        }
      });
  }, 2000);
}

function handlePayrollSave(evt) {
  evt.preventDefault();

  const payrollErrorContainer = document.getElementById('payroll-errors');
  payrollErrorContainer.classList.add('d-none');

  const formData = new FormData(evt.currentTarget);
  const serializedFormData = Object.fromEntries(formData.entries());

  const output = {
    payroll: {
      version: serializedFormData['payroll[version]'],
      employee_compensations: JSON.parse(serializedFormData['payroll[employee_compensations]']),
      pay_period_start_date: serializedFormData['payroll[pay_period_start_date]'],
      pay_period_end_date: serializedFormData['payroll[pay_period_end_date]'],
    },
  };

  const jsonString = JSON.stringify(output);
  const url = evt.currentTarget.action;

  fetch(url, {
    method: serializedFormData['_method'] === 'put' ? 'PUT' : 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: jsonString,
  }).then((response) => {
    if (response.status > 399) {
      reEnableDisabledElements();
      response.json().then((json) => {
        const payrollErrorContainer = document.getElementById('payroll-errors');
        const errorList = json.errors.map((msg) => `<li>${msg}</li>`);
        payrollErrorContainer.innerHTML =
          '<h5>There were some problems saving the payroll</h5>' + `<ul>${errorList.join('')}</ul>`;
        payrollErrorContainer.classList.remove('d-none');
      });
    } else {
      return response.json().then((json) => pollPayrollStatus(json.status_url));
    }
  });
}

function handlePayrollSubmit(evt) {
  evt.preventDefault();

  const formData = new FormData(evt.currentTarget);
  const serializedFormData = Object.fromEntries(formData.entries());
  var output = {};
  if (
    serializedFormData['submission_blockers[blocker_type]'] &&
    serializedFormData['submission_blockers[selected_option]']
  ) {
    output = {
      submission_blockers: [
        {
          blocker_type: serializedFormData['submission_blockers[blocker_type]'],
          selected_option: serializedFormData['submission_blockers[selected_option]'],
        },
      ],
    };

    document.querySelectorAll('button.submit-payroll').forEach((submitButton) => {
      submitButton.disabled = true;
    });
  }
  const jsonString = JSON.stringify(output);

  const url = evt.currentTarget.action;
  fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: jsonString,
  })
    .then((response) => response.json())
    .then((json) => {
      pollPayrollStatus(json.status_url);
    });
}

function handleGenerateChecks(evt) {
  evt.preventDefault();

  const formData = new FormData(evt.currentTarget);
  const serializedFormData = Object.fromEntries(formData.entries());
  const output = {
    payroll_checks: {
      printing_format: serializedFormData['payroll_checks[printing_format]'],
      starting_check_number: serializedFormData['payroll_checks[starting_check_number]'],
    },
  };

  const jsonString = JSON.stringify(output);

  const url = evt.currentTarget.action;
  fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: jsonString,
  }).then((response) => {
    if (response.status > 399) {
      reEnableDisabledElements();
      response.json().then((json) => {
        const payrollChecksErrorContainer = document.getElementById('payroll-checks-errors');
        const errorList = json.errors.map((msg) => `<li>${msg}</li>`);
        payrollChecksErrorContainer.innerHTML =
          '<h6>There were some problems generating payroll checks</h6>' +
          `<ul>${errorList.join('')}</ul>`;
        payrollChecksErrorContainer.classList.remove('d-none');
      });
    } else {
      response.json().then((json) => pollPayrollStatus(json.status_url));
    }
  });
}

document.addEventListener('DOMContentLoaded', () => {
  const payrollForm = document.getElementById('run-payroll-form');
  if (payrollForm) {
    payrollForm.addEventListener('submit', handlePayrollSave);
  }

  document.querySelectorAll('.submit-payroll-form').forEach((submitForm) => {
    submitForm.addEventListener('submit', handlePayrollSubmit);
  });

  const generateChecksForm = document.getElementById('payroll-checks-form');
  if (generateChecksForm) {
    generateChecksForm.addEventListener('submit', handleGenerateChecks);
  }
});
