import { hideElement, showElement, hideAriaElement, showAriaElement } from '@/common/toggleElement';

document.addEventListener('DOMContentLoaded', () => {
  const triggerPreview = document.getElementById('save-payments');
  const editPayments = document.getElementById('edit-payments');
  const previewDebitDate = document.getElementById('preview-debit-date');
  const contractorPaymentsInput = document.getElementById('contractor_payments_data');
  const dateInput = document.getElementById('contractor_payments_date');
  const dateInputError = document.getElementById('invalid-payment-date');
  const alertPaymentErrors = document.getElementById('alert-payment-errors');
  const newContexts = document.getElementsByClassName('new-payments');
  const previewContexts = document.getElementsByClassName('preview-payments');
  const contractorPaymentsTable = document.getElementsByClassName('contractor-payment-table')[0];
  const previewTable = document.getElementsByClassName('preview-table')[0];

  function formatDate(date) {
    const [year, month, day] = date.split('-');
    return [month, day, year].join('/');
  }

  function updatePreviewData(preview_response) {
    const currencyFormatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    const items = previewTable.getElementsByTagName('td');
    items[0].innerHTML = currencyFormatter.format(preview_response.total_amount);
    if (preview_response.total_check_amount) {
      items[1].innerHTML = currencyFormatter.format(preview_response.total_check_amount);
    }
    if (preview_response.account_type && preview_response.hidden_account_number) {
      items[2].innerHTML = [
        preview_response.account_type,
        preview_response.hidden_account_number,
      ].join(' ');
    }
    if (preview_response.expected_debit_date) {
      items[3].innerHTML = formatDate(preview_response.expected_debit_date);
      previewDebitDate.innerHTML = formatDate(preview_response.expected_debit_date);
    } else {
      previewDebitDate.innerHTML = formatDate(preview_response.expected_check_date);
    }
    items[4].innerHTML = formatDate(preview_response.expected_check_date);
  }

  async function preview() {
    const url = triggerPreview.getAttribute('href');
    if (contractorPaymentsInput && dateInput) {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          date: dateInput.value,
          contractor_payments: contractorPaymentsInput.value,
        }),
      });

      const jsonResponse = await response.json();

      switch (response.status) {
        case 200:
          for (const newItem of newContexts) {
            hideElement(newItem);
            hideAriaElement(newItem);
          }
          for (const previewItem of previewContexts) {
            showElement(previewItem);
            showAriaElement(previewItem);
          }
          resetPaymentsErrors();
          updatePreviewData(jsonResponse);
          break;
        default:
          setPaymentsErrors(jsonResponse);
      }
    }
  }

  function setPaymentsErrors(errors) {
    if (errors.date_errors && errors.date_errors.length >= 1) {
      dateInput.classList.add('is-invalid');
      dateInputError.innerHTML = errors.date_errors.join('\n');
    } else {
      resetDateErrors();
    }

    if (errors.alert_errors && errors.alert_errors.length >= 1) {
      alertPaymentErrors.innerHTML = errors.alert_errors.join('\n');
      alertPaymentErrors.classList.add('alert', 'alert-danger');
      alertPaymentErrors.setAttribute('role', 'alert');
    } else {
      resetAlertErrors();
    }
  }

  function resetDateErrors() {
    dateInputError.innerHTML = '';
    dateInput.classList.remove('is-invalid');
  }

  function resetAlertErrors() {
    alertPaymentErrors.innerHTML = '';
    alertPaymentErrors.classList.remove('alert', 'alert-danger');
    alertPaymentErrors.removeAttribute('role', 'alert');
  }

  function resetPaymentsErrors() {
    resetAlertErrors();
    resetDateErrors();
    dateInput.classList.add('disable-date-edit');
    contractorPaymentsTable.classList.remove('editable');
    dateInput.setAttribute('readonly', true);
  }

  function resetEdit() {
    for (const newItem of newContexts) {
      showElement(newItem);
    }
    for (const previewItem of previewContexts) {
      hideElement(previewItem);
    }
    contractorPaymentsTable.classList.add('editable');
    dateInput.classList.remove('disable-date-edit');
    dateInput.setAttribute('readonly', false);
  }

  if (triggerPreview) {
    triggerPreview.addEventListener('click', async (event) => {
      event.preventDefault();
      await preview();
    });
  }

  if (editPayments) {
    editPayments.addEventListener('click', resetEdit);
  }
});
