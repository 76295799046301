document.addEventListener('DOMContentLoaded', () => {
  const hexcolorSelectors = document.querySelectorAll('[data-color-selector]');

  hexcolorSelectors.forEach((elem) => {
    const colorInput = elem.querySelector('.js-color-input');
    const colorSelector = elem.querySelector('.js-color-selector');

    colorSelector.addEventListener('input', (event) => {
      colorInput.value = event.target.value;
    });

    colorInput.addEventListener('input', (event) => {
      const val = event.target.value;
      if (val && /^#[0-9a-f]{6}$/i.test(val)) {
        colorSelector.value = val;
      }
    });
  });
});
