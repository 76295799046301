const toggleTableRows = (table, state) => {
  const matchingRows = table.querySelectorAll(`[data-row-state~=${state}]`);
  matchingRows.forEach((row) => row.toggleAttribute('hidden'));
};

document.addEventListener('DOMContentLoaded', () => {
  const tableToggles = document.querySelectorAll('[data-table-toggle-rows]');

  tableToggles.forEach((toggle) => {
    const table = toggle.closest('table');
    const state = toggle.dataset['tableToggleRows'];

    toggle.addEventListener('change', () => toggleTableRows(table, state));
  });
});
