function runPayroll(evt) {
  evt.preventDefault();
  var payrollSelect = document.getElementById(evt.target.dataset.payrollSelectId);
  if (payrollSelect.value) {
    window.location = payrollSelect.value;
  }
}

function enableDisableRunPayroll(evt) {
  var runPayrollButton = document.getElementById(evt.target.dataset.runPayrollButtonId);
  if (evt.target.value) {
    runPayrollButton.disabled = false;
  } else {
    runPayrollButton.disabled = true;
  }
}

document.addEventListener('DOMContentLoaded', () => {
  var runPayrollButton = document.getElementById('run-payroll-submit');
  if (runPayrollButton) {
    runPayrollButton.addEventListener('click', runPayroll);
    var payrollSelect = document.getElementById(runPayrollButton.dataset.payrollSelectId);
    payrollSelect.addEventListener('change', enableDisableRunPayroll);
  }
});
