import { Modal } from 'bootstrap';

document.addEventListener('DOMContentLoaded', () => {
  const modalToggles = document.querySelectorAll('a[data-confirmation]');
  if (modalToggles.length == 0) {
    return;
  }

  const confirmationModals = {};
  modalToggles.forEach((toggle) => {
    const associatedModal = document.getElementById(toggle.dataset.confirmation);
    if (!associatedModal) {
      console.error(`Could not find modal id associated to toggle #${toggle.dataset.confirmation}`);
      return;
    }

    confirmationModals[associatedModal.id] ??= new Modal(associatedModal);

    toggle.addEventListener('click', (e) => {
      e.preventDefault();
      confirmationModals[associatedModal.id].show();
      const form = associatedModal.querySelector('form');
      if (form) {
        form.action = toggle.href;
      } else {
        const submitLink = associatedModal.querySelector('a[data-submit]');
        submitLink.href = toggle.href;
      }
    });
  });
});
