document.addEventListener('DOMContentLoaded', () => {
  const documentFields = window.documentFields;
  if (!documentFields) {
    return;
  }

  var documentPages = document.querySelectorAll('.document-page-image');
  documentPages.forEach((docPage) => {
    docPage.addEventListener('load', (evt) => {
      var img = evt.target;
      var pageNumber = parseInt(img.dataset.pageNumber, 10);
      var realWidth = img.naturalWidth / 2.0;
      var renderedWidth = img.clientWidth;
      var pageScale = renderedWidth / realWidth;

      documentFields.forEach((field) => {
        if (field.page_number === pageNumber) {
          var fieldWrapper = document.getElementById(`field-wrapper-${field.key}`);

          fieldWrapper.style.left = `${field.x * pageScale}px`;
          fieldWrapper.style.top = `${field.y * pageScale}px`;
          fieldWrapper.style.width = `${field.width * pageScale}px`;
          fieldWrapper.style.height = `${field.height * pageScale}px`;

          var fieldDOM = document.getElementById(field.key);
          fieldDOM.style.width = `${field.width * pageScale}px`;
          fieldDOM.style.height = `${field.height * pageScale}px`;
        }
      });
    });
  });

  var nextBtn = document.getElementById('btn-next');

  if (!nextBtn) {
    return;
  }

  var currentIndex = -1;
  nextBtn.addEventListener('click', (evt) => {
    evt.preventDefault();
    evt.target.innerHTML = 'Next';
    if (currentIndex >= documentFields.length - 1) {
      currentIndex = -1;
      document.getElementById('document_agree').focus();
    } else {
      currentIndex += 1;
      document.getElementById(window.documentFields[currentIndex].key).focus();
    }
  });
});
