import type axe from 'axe-core';
import BaseController from './base';

export default class extends BaseController {
  declare readonly impactTarget: HTMLSpanElement;
  declare readonly descriptionTarget: HTMLSpanElement;
  declare readonly hintTarget: HTMLParagraphElement;
  declare readonly helpLinkTarget: HTMLLinkElement;

  declare readonly sourceValue: axe.Result;
  declare readonly severityValue: string;
  declare readonly moderateImpactClasses: string[];
  declare readonly defaultImpactClasses: string[];
  static classes = ['moderateImpact', 'severeImpact', 'defaultImpact'];

  static values = { source: Object };
  static targets = ['description', 'impact', 'hint', 'helpLink'];

  impactCSSClasses(impact: axe.ImpactValue | null) {
    if (!impact) {
      return this.defaultImpactClasses;
    }

    const mappings = {
      minor: this.moderateImpactClasses,
      moderate: this.moderateImpactClasses,
      serious: this.moderateImpactClasses,
      critical: this.moderateImpactClasses,
    };
    return mappings[impact];
  }

  logToConsole() {
    /* eslint-disable no-console */
    console.groupCollapsed(`[A11Y]: ${this.sourceValue.description}`);
    console.log('issue:', this.sourceValue);
    console.log('nodes', this.sourceValue.nodes);
    console.groupEnd();
    /* eslint-enable no-console */
  }

  connect() {
    this.descriptionTarget.innerText = this.sourceValue.description;
    this.impactTarget.innerText = this.sourceValue.impact ?? 'Unknown';
    this.hintTarget.innerText = this.sourceValue.help;
    this.helpLinkTarget.href = this.sourceValue.helpUrl;

    this.impactTarget.className = '';
    this.impactTarget.classList.add(
      ...this.defaultImpactClasses,
      ...this.impactCSSClasses(this.sourceValue.impact ?? null),
    );
  }
}
