import BaseController from './base';
import { showElement, hideElement } from '@/common/toggleElement';

export default class HistoricalEmployeesController extends BaseController {
  static targets = [
    'workAddressSelect',

    'waWcSection',
    'waWcCoveredOption', // multiple
    'waWcClassCodeSelect',

    'wyWcSection',
    'wyWcCoveredOption', // multiple
    'wyWcClassCodeSelect',
  ];

  connect() {
    this.workStates = JSON.parse(this.workAddressSelectTarget.dataset.workStates);
    const state = this.workStates[this.workAddressSelectTarget.value];
    this.displayStateTaxFields(state);
  }

  displayStateTaxFields(state) {
    if (state === 'WA') {
      this.hideWyStateFields();
      this.showWaStateFields();
    } else if (state === 'WY') {
      this.hideWaStateFields();
      this.showWyStateFields();
    } else {
      this.hideWaStateFields();
      this.hideWyStateFields();
    }
  }

  workStateSelected(event) {
    const state = this.workStates[event.target.value];
    this.displayStateTaxFields(state);
  }

  showWaStateFields() {
    const covered = this.waWcCoveredOptionTargets.find((t) => t.checked)?.value === 'true';
    covered
      ? showElement(this.waWcClassCodeSelectTarget)
      : hideElement(this.waWcClassCodeSelectTarget);
    showElement(this.waWcSectionTarget);
  }

  hideWaStateFields() {
    hideElement(this.waWcSectionTarget);
  }

  waWcCoverageOptionSelected(event) {
    if (event.target.value === 'true') {
      showElement(this.waWcClassCodeSelectTarget);
    } else {
      hideElement(this.waWcClassCodeSelectTarget);
    }
  }

  showWyStateFields() {
    const covered = this.wyWcCoveredOptionTargets.find((t) => t.checked)?.value === 'true';
    covered
      ? showElement(this.wyWcClassCodeSelectTarget)
      : hideElement(this.wyWcClassCodeSelectTarget);
    showElement(this.wyWcSectionTarget);
  }

  hideWyStateFields() {
    hideElement(this.wyWcSectionTarget);
  }

  wyWcCoverageOptionSelected(event) {
    if (event.target.value === 'true') {
      showElement(this.wyWcClassCodeSelectTarget);
    } else {
      hideElement(this.wyWcClassCodeSelectTarget);
    }
  }
}
