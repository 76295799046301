/**
 * Allow select / clearance of all checkboxes in a grid.
 */
document.addEventListener('DOMContentLoaded', () => {
  const grids = document.querySelectorAll('[data-checkbox-grid]');

  grids.forEach((element) => {
    const checkboxes = element.querySelectorAll('input[type=checkbox]');

    const clearButtons = element.querySelectorAll('[data-clear]');
    const selectAllButtons = element.querySelectorAll('[data-select-all]');

    const toggleChecks = (value) => {
      checkboxes.forEach((e) => (e.checked = value));
    };
    const selectAll = () => toggleChecks(true);
    const clearAll = () => toggleChecks(false);

    clearButtons.forEach((e) => e.addEventListener('click', clearAll));
    selectAllButtons.forEach((e) => e.addEventListener('click', selectAll));
  });
});
