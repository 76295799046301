import BaseController from './base';

const HIDDEN_CLASS = 'd-none';
const STIMULUS_DEBUG_STORAGE_KEY = 'stimulus.debug';

export default class extends BaseController {
  static targets = ['panel', 'stimulusDebug'];

  static afterLoad(identifier, application) {
    application.debug =
      import.meta.env.MODE === 'development' &&
      localStorage.getItem(STIMULUS_DEBUG_STORAGE_KEY) !== 'disabled';
  }

  connect() {
    this.setDebugLabel();
  }

  toggle() {
    if (this.panelTarget.classList.contains(HIDDEN_CLASS)) {
      this.panelTarget.classList.remove(HIDDEN_CLASS);
    } else {
      this.panelTarget.classList.add(HIDDEN_CLASS);
    }
  }

  toggleStimulusDebug() {
    this.application.debug = !this.application.debug;
    localStorage.setItem(STIMULUS_DEBUG_STORAGE_KEY, this.stimulusDebugState);
    this.setDebugLabel();
  }

  setDebugLabel() {
    this.stimulusDebugTarget.innerHTML = this.stimulusDebugState;
  }

  get stimulusDebugState() {
    return this.application.debug ? 'enabled' : 'disabled';
  }
}
