document.addEventListener('DOMContentLoaded', () => {
  let paymentMethodToggle = document.querySelectorAll(
    'input[name="employee_payment_method[type]"]',
  );
  let directDeposit = document.querySelector('#direct_deposit_view');
  let check = document.querySelector('#check_view');
  let selectedElement = document.querySelector(
    'input[name="employee_payment_method[type]"]:checked',
  );

  function changeDisplay(type) {
    if (type == 'Check') {
      check.style.display = 'block';
      directDeposit.style.display = 'none';
    } else {
      check.style.display = 'none';
      directDeposit.style.display = 'block';
    }
  }

  if (selectedElement != null) {
    changeDisplay(selectedElement.value);
    paymentMethodToggle.forEach((toggle) => {
      toggle.addEventListener('change', (e) => {
        let type = e.target.value;
        changeDisplay(type);
      });
    });
  }
});
