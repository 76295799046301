import BaseController from './base';
import { postFlowMessage } from '@/common/events';
import type { FlowMessageMetadata } from '@/common/events';

export default class extends BaseController {
  declare eventValue: string;
  declare emitOnConnectValue: boolean;
  declare metadataValue: FlowMessageMetadata;

  static values = {
    event: String,
    metadata: Object,
    emitOnConnect: { type: Boolean, default: false },
  };

  connect() {
    if (this.emitOnConnectValue) {
      this.emit();
    }
  }

  emit() {
    postFlowMessage(top as Window, this.eventValue, this.metadataValue);
  }
}
