import { showElement, hideElement } from '@/common/toggleElement';

document.addEventListener('DOMContentLoaded', () => {
  const finalPaycheck = document.querySelectorAll('.js_payroll_type');
  const dismissalPayroll = document.getElementById('dismissal_payroll_warning');
  const regularPayroll = document.getElementById('regular_payroll_warning');

  function selectedPayrollTypeValue() {
    let selectedElement = document.querySelector('.js_payroll_type:checked');
    return selectedElement ? selectedElement.value : '';
  }

  function togglePayrollWarning(payrollType) {
    switch (payrollType) {
      case 'dismissal':
        showElement(dismissalPayroll);
        hideElement(regularPayroll);
        break;
      case 'regular':
      case 'off_cycle':
        showElement(regularPayroll);
        hideElement(dismissalPayroll);
        break;
      default:
        hideElement(regularPayroll);
        hideElement(dismissalPayroll);
    }
  }

  if (finalPaycheck && finalPaycheck.length !== 0) {
    togglePayrollWarning(selectedPayrollTypeValue());
    finalPaycheck.forEach((element) => {
      element.addEventListener('change', () => togglePayrollWarning(element.value));
    });
  }
});
