document.addEventListener('DOMContentLoaded', () => {
  const configPreviewButtons = document.getElementsByClassName('preview-style');
  if (configPreviewButtons.length == 0) {
    return;
  }

  const styleInputs = document.querySelectorAll('[name^="partner_configs[styles."]');
  if (!styleInputs) {
    return;
  }

  const appendStyleParams = (link) => {
    const previewUrl = new URL(link.href);
    styleInputs.forEach((input) => {
      const name = `styles[${input.dataset.style}]`;
      if (input.value) {
        previewUrl.searchParams.append(name, input.value);
      } else {
        previewUrl.searchParams.delete(name);
      }
    });
    link.href = previewUrl.href;
  };

  ['click', 'auxclick'].forEach((type) => {
    Array.from(configPreviewButtons).forEach((link) => {
      link.target = '_blank';
      link.addEventListener(type, (event) => {
        const link = event.currentTarget;
        appendStyleParams(link);
      });
    });
  });
});
