document.addEventListener('DOMContentLoaded', () => {
  const managePaySchedulePreview = document.getElementById('manage_pay_schedule_preview');
  const hidePreviewCalendarButton = document.getElementById('hide-preview-calendar-button');

  if (hidePreviewCalendarButton != null) {
    hidePreviewCalendarButton.addEventListener('click', () => {
      managePaySchedulePreview.toggleAttribute('hidden');
    });
  }
});
