import { showElement, hideElement } from '@/common/toggleElement';

document.addEventListener('DOMContentLoaded', () => {
  let paymentTypeElement = document.querySelectorAll('.js_payment_type');
  let directDepositPaymentDetailsView = document.querySelector('.js_direct_deposit_details');
  let checkPaymentDetailsView = document.querySelector('.js_check_details');

  function selectedPaymentTypeValue() {
    let selectedElement = document.querySelector('.js_payment_type:checked');
    return selectedElement ? selectedElement.value : '';
  }

  function displayDetailsForPaymentType(paymentMethod) {
    switch (paymentMethod) {
      case 'Check':
        showElement(checkPaymentDetailsView);
        hideElement(directDepositPaymentDetailsView);
        break;
      case 'Direct deposit':
        hideElement(checkPaymentDetailsView);
        showElement(directDepositPaymentDetailsView);
        break;
      default:
        hideElement(checkPaymentDetailsView);
        hideElement(directDepositPaymentDetailsView);
    }
  }

  if (paymentTypeElement && paymentTypeElement.length !== 0) {
    displayDetailsForPaymentType(selectedPaymentTypeValue());
    paymentTypeElement.forEach((element) => {
      element.addEventListener('change', () => displayDetailsForPaymentType(element.value));
    });
  }
});
