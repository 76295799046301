import { postFlowMessage } from '@/common/events';

document.addEventListener('DOMContentLoaded', () => {
  const finishButtons = document.querySelectorAll('a[data-flow-event]');
  if (finishButtons.length) {
    finishButtons.forEach((elem) => {
      elem.addEventListener('click', (event) => {
        event.preventDefault();

        const { flowEvent, metadata } = event.target.dataset;

        postFlowMessage(top, flowEvent, JSON.parse(metadata));
      });
    });
  }
});
