document.addEventListener('DOMContentLoaded', () => {
  const select_new_hire_report = document.getElementById('contractor_file_new_hire_report_true');
  const select_no_new_hire_report = document.getElementById(
    'contractor_file_new_hire_report_false',
  );
  const workState = document.getElementById('work_state');

  const form_visibility = (show) => (workState.style.display = show ? 'block' : 'none');

  document.addEventListener('DOMContentLoaded', () => {
    if (select_no_new_hire_report.checked) {
      form_visibility(false);
    }
  });
  if (select_new_hire_report != null) {
    form_visibility(select_new_hire_report.checked);
    select_new_hire_report.onclick = () => form_visibility(true);
    select_no_new_hire_report.onclick = () => form_visibility(false);
  }
});
