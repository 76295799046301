export interface FlowMessageMetadata {
  flowType: string;
  companyUuid: string;
}

const postFlowMessage = (frame: Window, event: string, metadata: FlowMessageMetadata) => {
  // frame is usually parent (1 layer up) or top (top most frame)
  frame.postMessage(
    {
      event,
      metadata,
    },
    '*',
  );
};

export { postFlowMessage };
