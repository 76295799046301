import { accounting } from '@rasgo/accounting';
import { Modal } from 'bootstrap';

let bootstrapCompensationModal;

const COMPENSATION_INPUT_CONFIGS = [
  { name: 'Hours', wageType: 'Hourly', property: 'hours', id: 'input-regular-hours' },
  { name: 'Wage', wageType: 'Fixed', property: 'wage', id: 'input-owners-draw' },
  { name: 'Bonus', wageType: 'Hourly', property: 'bonus', id: 'input-bonus' },
  {
    name: 'Reimbursement',
    wageType: 'Both',
    property: 'reimbursement',
    id: 'input-reimbursements',
  },
  { name: 'Total', wageType: 'Both', property: 'wage_total', id: 'modal-total-pay' },
  { name: 'Check', wageType: 'Both', property: 'payment_method', id: 'input-payment-method-check' },
  {
    name: 'Direct Deposit',
    wageType: 'Both',
    property: 'payment_method',
    id: 'input-payment-method-direct-deposit',
  },
];

class ContractorCompensation {
  constructor(contractorUuid) {
    this.contractorUuid = contractorUuid;
    this.compensationDetails = window.contractorsCompensationDetails[contractorUuid];
    this.contractorCompensationsInput = document.getElementById('contractor_payments_data');
    this.contractorCompensations = JSON.parse(this.contractorCompensationsInput.value);
    this.contractorCompensation = this.contractorCompensations.find(
      (comp) => comp.contractor_uuid === this.contractorUuid,
    );
  }

  writeCompensations() {
    this.contractorCompensationsInput.value = JSON.stringify(this.contractorCompensations);
  }

  updateCompensationItem(config, value) {
    if (this.contractorCompensation) {
      this.contractorCompensation[config.property] = value;
    }
  }
}

let currentContractorUuid;
let contractorCompensationClone;

function handleOpenCompensationModal(evt) {
  evt.preventDefault();

  bootstrapCompensationModal.show();

  const editLink = evt.target;
  currentContractorUuid = editLink.dataset.contractorUuid;
  const contractorCompensation = new ContractorCompensation(currentContractorUuid);

  // Clone another copy of the contractorCompensation in memory
  contractorCompensationClone = new ContractorCompensation(currentContractorUuid);

  const contractorNameSpan = document.getElementById('compensation-name');
  contractorNameSpan.innerHTML = window.contractorsCompensationDetails[currentContractorUuid].name;

  COMPENSATION_INPUT_CONFIGS.forEach((config) => {
    configureInput(config, contractorCompensation.contractorCompensation);
  });

  updateModalTotalPay(contractorCompensationClone.contractorCompensation);
}

function configureInput(config, contractorCompensation) {
  const compensationInput = document.getElementById(config.id);
  if (config.property === 'payment_method') {
    const check = document.getElementById('input-payment-method-check');
    const dd = document.getElementById('input-payment-method-direct-deposit');
    const historical = document.getElementById('input-payment-method-historical-payment');
    dd.disabled = contractorCompensation.disable_payment_change;
    check.checked = contractorCompensation.payment_method === 'Check';
    dd.checked = contractorCompensation.payment_method === 'Direct Deposit';
    historical.checked = contractorCompensation.payment_method === 'Historical Payment';
  } else {
    if (contractorCompensation[config.property] != null) {
      compensationInput.value = contractorCompensation[config.property];
    } else {
      compensationInput.value = 0;
    }
    if (config.wageType !== contractorCompensation.wage_type && config.wageType !== 'Both') {
      compensationInput.disabled = true;
    } else {
      compensationInput.disabled = false;
    }
  }
}

function calculateTotalPay(payment, hourlyRate) {
  let amount = 0;
  if (payment.wage_type === 'Hourly') {
    amount += hourlyRate * Number(payment.hours);
  } else {
    amount += getWage(payment);
  }
  amount += getBonus(payment);
  amount += getReimbursement(payment);
  return amount;
}

function getBonus(payment) {
  return Number(payment.bonus) || 0;
}

function getReimbursement(payment) {
  return Number(payment.reimbursement) || 0;
}

function getWage(payment) {
  return Number(payment.wage);
}

function setAllTotals() {
  const paymentInput = document.getElementById('contractor_payments_data');
  const contractorPayments = JSON.parse(paymentInput.value);
  let bonusTotal = 0;
  let reimbursementTotal = 0;
  let wageTotal = 0;
  let grandTotal = 0;

  contractorPayments.forEach((payment) => {
    const compDetails = window.contractorsCompensationDetails[payment.contractor_uuid];
    grandTotal += calculateTotalPay(payment, Number(compDetails.hourly_rate));
    bonusTotal += getBonus(payment);
    reimbursementTotal += getReimbursement(payment);
    wageTotal += getWage(payment);
  });

  const totalsRow = document.querySelector('tr.contractor-totals-row');
  const totalsWageCell = totalsRow.querySelector('td.cell-totals-wages');
  const totalsBonusCell = totalsRow.querySelector('td.cell-totals-bonus');
  const totalsReimbursementCell = totalsRow.querySelector('td.cell-totals-reimbursement');
  const totalsCell = totalsRow.querySelector('td.cell-totals');

  totalsWageCell.innerHTML = accounting.formatMoney(wageTotal);
  totalsBonusCell.innerHTML = accounting.formatMoney(bonusTotal);
  totalsReimbursementCell.innerHTML = accounting.formatMoney(reimbursementTotal);
  totalsCell.innerHTML = accounting.formatMoney(grandTotal);
}
function updateCompensationRow(contractorCompensation) {
  const compensationRow = document.querySelector(
    `tr.contractor-compensation-row[data-contractor-uuid="${contractorCompensation.contractor_uuid}"]`,
  );
  const paymentMethodCell = compensationRow.querySelector('td.cell-payment-method');
  const hoursCell = compensationRow.querySelector('td.cell-hours');
  const reimbursementCell = compensationRow.querySelector('td.cell-reimbursement');
  const wageCell = compensationRow.querySelector('td.cell-wage');
  const bonusCell = compensationRow.querySelector('td.cell-bonus');
  const totalPayCell = compensationRow.querySelector('td.cell-total');

  const compDetails = window.contractorsCompensationDetails[contractorCompensation.contractor_uuid];
  const totalPay = calculateTotalPay(contractorCompensation, Number(compDetails.hourly_rate));

  hoursCell.innerHTML = Number(contractorCompensation.hours).toFixed(3);
  wageCell.innerHTML = accounting.formatMoney(contractorCompensation.wage);
  reimbursementCell.innerHTML = accounting.formatMoney(contractorCompensation.reimbursement);
  bonusCell.innerHTML = accounting.formatMoney(contractorCompensation.bonus);
  totalPayCell.innerHTML = accounting.formatMoney(totalPay);
  paymentMethodCell.innerHTML = contractorCompensation.payment_method;
}

// Create a ContractorCompensation object based on information displayed in modal
function newContractorCompensation() {
  const contractorCompensation = new ContractorCompensation(currentContractorUuid);
  COMPENSATION_INPUT_CONFIGS.forEach((config) => {
    const input = document.getElementById(config.id);
    if (!input.disabled) {
      contractorCompensation.updateCompensationItem(config, input.value);
    }
  });
  contractorCompensation.contractorCompensation['payment_method'] = document.querySelector(
    'input[name="payment_method"]:checked',
  ).value;
  return contractorCompensation;
}

function updateCompensation(evt) {
  evt.preventDefault();
  const contractorCompensation = newContractorCompensation();
  contractorCompensation.writeCompensations();
  setAllTotals();
  updateCompensationRow(contractorCompensation.contractorCompensation);

  bootstrapCompensationModal.hide();
}

function updateModalTotalPay(contractorCompensation) {
  const compDetails = window.contractorsCompensationDetails[currentContractorUuid];
  const grossPay = calculateTotalPay(contractorCompensation, Number(compDetails.hourly_rate));

  const modalGrossPayContainer = document.getElementById('modal-total-pay');
  modalGrossPayContainer.innerHTML = accounting.formatMoney(grossPay);
  modalGrossPayContainer.value = grossPay.toFixed(2);
}

function handleCompensationInput(evt) {
  const inputDOM = evt.target;
  const config = COMPENSATION_INPUT_CONFIGS.find((config) => config.id === inputDOM.id);

  contractorCompensationClone.updateCompensationItem(config, inputDOM.value);
  updateModalTotalPay(contractorCompensationClone.contractorCompensation);
}

document.addEventListener('DOMContentLoaded', () => {
  const editCompensationModal = document.getElementById('contractor-compensation-modal');
  if (editCompensationModal) {
    bootstrapCompensationModal = new Modal(editCompensationModal, {});
  }

  const editButtons = document.querySelectorAll('.edit-contractor-compensations');
  if (editButtons) {
    editButtons.forEach((btn) => {
      btn.addEventListener('click', handleOpenCompensationModal);
    });
  }

  const updateButton = document.getElementById('update-payment-method-form');
  if (updateButton) {
    updateButton.addEventListener('submit', updateCompensation);
  }

  const modalInputs = document.querySelectorAll(
    '#contractor-compensation-modal input[type="text"]',
  );
  if (modalInputs) {
    modalInputs.forEach((modalInput) => {
      modalInput.addEventListener('input', handleCompensationInput);
    });
  }
});
