import autoComplete from '@tarekraafat/autocomplete.js/src/autoComplete.js';
import BaseController from './base';

export default class extends BaseController {
  static targets = ['autoComplete', 'titleInput', 'naicsInput', 'sicInput'];
  static values = {
    searchPath: String,
  };

  connect() {
    this.autoCompleteInstance = new autoComplete({
      selector: () => this.autoCompleteTarget,
      data: {
        src: () =>
          fetch(
            `${this.searchPathValue}?query=${encodeURIComponent(this.autoCompleteTarget.value)}`,
            { method: 'GET' },
          )
            .then((source) => source.json())
            .catch((err) => err),
      },
      threshold: 2,
      debounce: 200,
      resultsList: {
        element: (list, data) => {
          const info = document.createElement('p');
          if (data.results.length === 0) {
            info.innerHTML = 'No results found';
            list.prepend(info);
          }
        },
        noResults: true,
        tabSelect: true,
        maxResults: 8,
      },
      searchEngine: function (query, record) {
        return record.title;
      },
      events: {
        input: {
          focus: () => {
            if (this.autoCompleteTarget.value.length) {
              this.autoCompleteInstance.start();
            }
          },
        },
      },
    });

    this.autoCompleteTarget.addEventListener('selection', (event) => {
      const newSelection = event.detail.selection.value;
      // Replace Input value with the selected value
      this.autoCompleteTarget.value = newSelection.title;

      this.titleInputTarget.value = newSelection.title;
      this.naicsInputTarget.value = newSelection.naics_code;
      this.sicInputTarget.value = newSelection.sic_codes.join(',');
    });
  }

  disconnect() {
    this.autoCompleteInstance.unInit();
  }
}
