import { Application, ControllerConstructor } from '@hotwired/stimulus';
import { controllerPathToStimulusControllerName } from '@/common/utilities';

const BASE_PATH = '/controllers';

const controllers = import.meta.glob('@/controllers/**/*_controller.*', {
  import: 'default',
  eager: true,
});

const application = Application.start();
for (const path in controllers) {
  const controllerName = controllerPathToStimulusControllerName(path, BASE_PATH);

  application.register(controllerName, controllers[path] as ControllerConstructor);
}
