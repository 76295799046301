/*
TODO: fix file to improve fetch/promise functions
*/
/* eslint-disable promise/catch-or-return, promise/always-return, promise/no-nesting */

function raiseAlert(plaidError, message) {
  plaidError.innerHTML = message;
  plaidError.className += 'alert alert-danger';
  plaidError.role = 'alert';
}

function clearAlert(plaidError) {
  plaidError.innerHTML = '';
  plaidError.className = '';
}

function generateLinkToken(
  linkTokenPath,
  submitPublicTokenPath,
  companyBankAccountPath,
  plaidError,
) {
  fetch(linkTokenPath, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((response) => {
    if (response.status === 200) {
      response.json().then((data) => {
        launchPlaid(data.link_token, submitPublicTokenPath, companyBankAccountPath, plaidError);
        clearAlert(plaidError);
      });
    } else {
      raiseAlert(
        plaidError,
        'Please re-click the Verify Account with Plaid button or manually enter a bank account.',
      );
    }
  });
}

function launchPlaid(link_token, submitPublicTokenPath, companyBankAccountPath, plaidError) {
  const handler = Plaid.create({
    token: link_token,
    linkCustomizationName: 'er_onboarding',
    onSuccess: (public_token, metadata) => {
      storePlaidData(metadata, submitPublicTokenPath, companyBankAccountPath, plaidError);
    },
  });
  handler.open();
}

function storePlaidData(metadata, submitPublicTokenPath, companyBankAccountPath, plaidError) {
  fetch(submitPublicTokenPath, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      plaid: {
        public_token: metadata.public_token,
        plaid_account_id: metadata.account_id || metadata.accounts[0].id,
        plaid_institution_id: metadata.institution.institution_id,
      },
    }),
  }).then((response) => {
    if (response.status === 201) {
      clearAlert(plaidError);
      window.location.href = companyBankAccountPath;
    } else {
      raiseAlert(
        plaidError,
        'Something went wrong. Please re-verify account through plaid or manually enter a bank account.',
      );
    }
  });
}

window.PlaidWidget = {
  init: function (params) {
    try {
      var errorContainerId = params.errorContainerId;
      var buttonId = params.linkId;
      var linkTokenPath = params.linkTokenPath;
      var submitPublicTokenPath = params.submitPublicTokenPath;
      var companyBankAccountPath = params.companyBankAccountPath;
      var plaidError = document.getElementById(errorContainerId);
      var linkTokenButton = document.getElementById(buttonId);

      linkTokenButton &&
        linkTokenButton.addEventListener('click', () => {
          generateLinkToken(
            linkTokenPath,
            submitPublicTokenPath,
            companyBankAccountPath,
            plaidError,
          );
        });
    } catch (error) {
      plaidError.innerHTML = 'Unable to use Plaid at this time.';
    }
  },
};
