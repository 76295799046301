import { showElement, hideElement } from '@/common/toggleElement';

document.addEventListener('DOMContentLoaded', () => {
  let contractorType = document.querySelectorAll('.js_contractor_type');
  let wageType = document.querySelectorAll('.js_wage_type');
  let individualDetailsView = document.querySelector('.js_individual_details');
  let businessDetailsView = document.querySelector('.js_business_details');
  let hourlyField = document.querySelector('.js_hourly_field');
  let contractorOnboardingStatusCheckbox = document.getElementById('contractor_self_onboarding');
  let individualSensitiveInfo = document.querySelector('.js_individual_sensitive_details');
  let businessSensitiveInfo = document.querySelector('.js_business_sensitive_details');

  function selectedContractorTypeValue() {
    let selectedElement = document.querySelector('.js_contractor_type:checked');
    let hiddenForContractorRoleElement = document.querySelector('.js_contractor_type.hidden');
    selectedElement = selectedElement ? selectedElement : hiddenForContractorRoleElement;
    return selectedElement ? selectedElement.value : '';
  }

  function selectedWageTypeValue() {
    let selectedElement = document.querySelector('.js_wage_type:checked');
    return selectedElement ? selectedElement.value : '';
  }

  function displayDetailsForContractorType(contractorType) {
    switch (contractorType) {
      case 'Business':
        showElement(businessDetailsView);
        hideElement(individualDetailsView);
        break;
      case 'Individual':
        showElement(individualDetailsView);
        hideElement(businessDetailsView);
        break;
      default:
        hideElement(businessDetailsView);
        hideElement(individualDetailsView);
    }
  }

  function displayDetailsForWageType(contractorWageType) {
    switch (contractorWageType) {
      case 'Fixed':
        hideElement(hourlyField);
        break;
      case 'Hourly':
        showElement(hourlyField);
        break;
      default:
        hideElement(hourlyField);
    }
  }

  function contractorIsSelfOnboarding() {
    return contractorOnboardingStatusCheckbox && selfOnboardingValue();
  }

  function selfOnboardingValue() {
    return contractorOnboardingStatusCheckbox.checked;
  }

  function displaySensitiveInfoForContractorType(contractorType) {
    switch (contractorType) {
      case 'Business':
        showElement(businessSensitiveInfo);
        hideElement(individualSensitiveInfo);
        break;
      case 'Individual':
        showElement(individualSensitiveInfo);
        hideElement(businessSensitiveInfo);
        break;
      default:
        hideSensitiveInfoForContractors();
    }
  }

  function hideSensitiveInfoForContractors() {
    hideElement(businessSensitiveInfo);
    hideElement(individualSensitiveInfo);
  }

  if (wageType && wageType.length !== 0) {
    displayDetailsForWageType(selectedWageTypeValue());
    wageType.forEach((element) => {
      element.addEventListener('change', () => displayDetailsForWageType(element.value));
    });
  }

  if (contractorType && contractorType.length !== 0) {
    displayDetailsForContractorType(selectedContractorTypeValue());
    displaySensitiveInfoForContractorType(selectedContractorTypeValue());
    contractorType.forEach((element) => {
      element.addEventListener('change', () => {
        displayDetailsForContractorType(element.value);
        if (contractorIsSelfOnboarding()) {
          hideSensitiveInfoForContractors();
        } else {
          displaySensitiveInfoForContractorType(element.value);
        }
      });
    });
  }

  if (contractorOnboardingStatusCheckbox) {
    if (contractorIsSelfOnboarding()) {
      hideSensitiveInfoForContractors();
    } else {
      displaySensitiveInfoForContractorType(selectedContractorTypeValue());
    }
    contractorOnboardingStatusCheckbox.addEventListener('change', (event) => {
      if (event.target.checked) {
        hideSensitiveInfoForContractors();
      } else {
        displaySensitiveInfoForContractorType(selectedContractorTypeValue());
      }
    });
  }
});
