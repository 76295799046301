import strftime from 'strftime';

/**
 * IMPORTANT: all date strings need to be in ISO 8601 format !
 */

export function toLocalDateTime(dateTimeStr) {
  return `${toTime(dateTimeStr)} on ${toDayAndDate(dateTimeStr)}`;
}

export function toLocalDateTimeWithYear(dateTimeStr) {
  return `${toTimeWithoutMinutes(dateTimeStr)} on ${toDayAndDate(dateTimeStr)}, ${new Date(
    dateTimeStr.trim(),
  ).getFullYear()}`;
}

export function toTime(dateTimeStr) {
  return strftime(`%-I:%M %p ${shortTimeZone(dateTimeStr)}`, new Date(dateTimeStr.trim()));
}

export function toTimeWithoutMinutes(dateTimeStr) {
  return strftime(`%-I%P ${shortTimeZone(dateTimeStr)}`, new Date(dateTimeStr.trim()));
}

export function toDayAndDate(dateTimeStr) {
  return strftime('%a %b %-d', new Date(dateTimeStr.trim()));
}

export function shortTimeZone(dateTimeStr) {
  return new Date(dateTimeStr.trim())
    .toLocaleDateString('en-US', {
      day: '2-digit',
      timeZoneName: 'short',
    })
    .slice(4);
}

document.addEventListener('DOMContentLoaded', () => {
  const dateTimes = document.querySelectorAll('.convert-to-local-datetime');
  if (dateTimes) {
    dateTimes.forEach((span) => {
      span.innerHTML = toLocalDateTime(span.innerHTML);
    });
  }

  const dateTimesWithYear = document.querySelectorAll('.convert-to-local-datetime-with-year');
  if (dateTimesWithYear) {
    dateTimesWithYear.forEach((span) => {
      span.innerHTML = toLocalDateTimeWithYear(span.innerHTML);
    });
  }

  const times = document.querySelectorAll('.convert-to-local-time');
  if (times) {
    times.forEach((span) => {
      span.innerHTML = toTime(span.innerHTML);
    });
  }
});
