/* eslint-disable promise/catch-or-return, promise/always-return, promise/no-nesting */
import { reEnableDisabledElements } from '@/common/disable-form-submissions';
import { showElement, hideElement } from '@/common/toggleElement';

const TIME_PERIOD_BETWEEN = 'Between two dates';
const TIME_PERIOD_BEFORE = 'Before';
const TIME_PERIOD_AFTER = 'After';

function pollReportStatus(url) {
  const reportErrors = document.getElementById('report-errors');

  setTimeout(() => {
    fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((resp) => resp.json())
      .then((json) => {
        if (json.status == 'succeeded') {
          reEnableDisabledElements();
          reportErrors.classList.add('d-none');
          window.location = json.redirect_url;
        } else if (json.status == 'failed') {
          reEnableDisabledElements();
          reportErrors.innerHTML =
            '<h6>There were some problems generating the report. Please try again.</h6>';
          reportErrors.classList.remove('d-none');
        } else {
          pollReportStatus(url);
        }
      });
  }, 2000);
}

function handleGenerateReport(evt) {
  evt.preventDefault();

  const formData = new FormData(evt.currentTarget);
  const serializedFormData = Object.fromEntries(formData.entries());

  const output = {
    report: {
      payroll_columns: formData.getAll('report[payroll_columns][]'),
      employee_columns: formData.getAll('report[employee_columns][]'),
      work_address_columns: formData.getAll('report[work_address_columns][]'),
      employer_columns: formData.getAll('report[employer_columns][]'),
      file_type: serializedFormData['report[file_type]'],
      with_totals: serializedFormData['report[with_totals]'],
      start_date: serializedFormData['report[start_date]'],
      end_date: serializedFormData['report[end_date]'],
      dismissed_start_date: serializedFormData['report[dismissed_start_date]'],
      employment_status: serializedFormData['report[employment_status]'],
      employment_type: serializedFormData['report[employment_type]'],
      payment_method: serializedFormData['report[payment_method]'],
      department_uuids: formData.getAll('report[department_uuids][]'),
    },
  };

  const jsonString = JSON.stringify(output);
  const url = evt.currentTarget.action;

  fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: jsonString,
  }).then((response) => {
    if (response.status > 399) {
      reEnableDisabledElements();
      response.json().then((json) => {
        const reportErrors = document.getElementById('report-errors');
        const errorList = json.errors.map((msg) => `<li>${msg}</li>`);
        reportErrors.innerHTML =
          '<h6>There were some problems generating the report</h6>' +
          `<ul>${errorList.join('')}</ul>`;
        reportErrors.classList.remove('d-none');
      });
    } else {
      response.json().then((json) => pollReportStatus(json.status_url));
    }
  });
}

function selectPayrollColumns() {
  const selectCountElm = document.getElementById('payroll-title');
  if (!selectCountElm) {
    return;
  }
  const payrollCheckboxes = document.querySelectorAll('input[name="report[payroll_columns][]"]');
  const selectAllText = document.getElementById('select-all-payroll');
  const clearAllText = document.getElementById('clear-all-payroll');

  function refreshTotalCount() {
    const checkedCount = Array.from(payrollCheckboxes).filter((e) => e.checked).length;
    selectCountElm.innerHTML = 'Payroll columns (' + checkedCount + ')';
  }

  payrollCheckboxes.forEach((payrollCheckbox) => {
    payrollCheckbox.onchange = () => refreshTotalCount();
  });

  selectAllText.addEventListener('click', () => {
    refreshTotalCount();
  });

  clearAllText.addEventListener('click', () => {
    refreshTotalCount();
  });
  refreshTotalCount();
}

function selectEmployeeColumns() {
  const selectCountElm = document.getElementById('employee-title');
  if (!selectCountElm) {
    return;
  }
  const employeeCheckboxes = document.querySelectorAll('input[name="report[employee_columns][]"]');
  const selectAllText = document.getElementById('select-all-employee');
  const clearAllText = document.getElementById('clear-all-employee');

  function refreshTotalCount() {
    const checkedCount = Array.from(employeeCheckboxes).filter((e) => e.checked).length;
    selectCountElm.innerHTML = 'Employee columns (' + checkedCount + ')';
  }

  employeeCheckboxes.forEach((employeeCheckbox) => {
    employeeCheckbox.onchange = () => refreshTotalCount();
  });

  selectAllText.addEventListener('click', () => {
    refreshTotalCount();
  });

  clearAllText.addEventListener('click', () => {
    refreshTotalCount();
  });
  refreshTotalCount();
}

function selectWorkAddressColumns() {
  const selectCountElm = document.getElementById('work-address-title');
  if (!selectCountElm) {
    return;
  }
  const workAddressCheckboxes = document.querySelectorAll(
    'input[name="report[work_address_columns][]"]',
  );
  const selectAllText = document.getElementById('select-all-work-address');
  const clearAllText = document.getElementById('clear-all-work-address');

  function refreshTotalCount() {
    const checkedCount = Array.from(workAddressCheckboxes).filter((e) => e.checked).length;
    selectCountElm.innerHTML = 'Work address columns (' + checkedCount + ')';
  }

  workAddressCheckboxes.forEach((workAddressCheckbox) => {
    workAddressCheckbox.onchange = () => refreshTotalCount();
  });

  selectAllText.addEventListener('click', () => {
    refreshTotalCount();
  });

  clearAllText.addEventListener('click', () => {
    refreshTotalCount();
  });
  refreshTotalCount();
}

function selectEmployerColumns() {
  const selectCountElm = document.getElementById('employer-title');
  if (!selectCountElm) {
    return;
  }
  const employerCheckboxes = document.querySelectorAll('input[name="report[employer_columns][]"]');
  const selectAllText = document.getElementById('select-all-employer');
  const clearAllText = document.getElementById('clear-all-employer');

  function refreshTotalCount() {
    const checkedCount = Array.from(employerCheckboxes).filter((e) => e.checked).length;
    selectCountElm.innerHTML = 'Employer columns (' + checkedCount + ')';
  }

  employerCheckboxes.forEach((employerCheckbox) => {
    employerCheckbox.onchange = () => refreshTotalCount();
  });

  selectAllText.addEventListener('click', () => {
    refreshTotalCount();
  });

  clearAllText.addEventListener('click', () => {
    refreshTotalCount();
  });
  refreshTotalCount();
}

function selectFilters() {
  const selectCountElm = document.getElementById('filters-title');
  if (!selectCountElm) {
    return;
  }
  let dateToggle = document.getElementById('time-period-filter-toggle');
  let departmentToggle = document.getElementById('department-filter-toggle');
  const selectAllText = document.getElementById('select-all-department');
  const clearAllText = document.getElementById('clear-all-department');
  let dismissedDateToggle = document.getElementById('dismissed-time-period-filter-toggle');
  let employmentStatusToggle = document.getElementById('employment-status-filter-toggle');
  let employmentTypeToggle = document.getElementById('employment-type-filter-toggle');
  let paymentMethodToggle = document.getElementById('payment-method-filter-toggle');

  let startDate = document.getElementById('report_start_date');
  let endDate = document.getElementById('report_end_date');
  const departmentCheckboxes = document.querySelectorAll(
    'input[name="report[department_uuids][]"]',
  );
  let dismissedStartDate = document.getElementById('report_dismissed_start_date');
  let dismissedEndDate = document.getElementById('report_dismissed_end_date');
  let employmentStatus = document.getElementById('report_employment_status');
  let employmentType = document.getElementById('report_employment_type');
  let paymentMethod = document.getElementById('report_payment_method');

  function refreshTotalCount() {
    var filterCount = 0;
    if (startDate?.value || endDate?.value) {
      filterCount++;
    }
    if (
      departmentCheckboxes &&
      Array.from(departmentCheckboxes).filter((e) => e.checked).length > 0
    ) {
      filterCount++;
    }
    if (dismissedStartDate?.value || dismissedEndDate?.value) {
      filterCount++;
    }
    if (employmentStatus?.value) {
      filterCount++;
    }
    if (employmentType?.value) {
      filterCount++;
    }
    if (paymentMethod?.value) {
      filterCount++;
    }

    selectCountElm.innerHTML = 'Filters (' + filterCount + ')';
  }

  startDate.addEventListener('change', () => {
    refreshTotalCount();
  });
  endDate.addEventListener('change', () => {
    refreshTotalCount();
  });
  departmentCheckboxes?.forEach((departmentCheckbox) => {
    departmentCheckbox.onchange = () => refreshTotalCount();
  });
  selectAllText.addEventListener('click', () => {
    refreshTotalCount();
  });

  clearAllText.addEventListener('click', () => {
    refreshTotalCount();
  });
  dismissedStartDate.addEventListener('change', () => {
    refreshTotalCount();
  });
  dismissedEndDate.addEventListener('change', () => {
    refreshTotalCount();
  });
  employmentStatus.addEventListener('change', () => {
    refreshTotalCount();
  });
  employmentType.addEventListener('change', () => {
    refreshTotalCount();
  });
  paymentMethod.addEventListener('change', () => {
    refreshTotalCount();
  });
  dateToggle.addEventListener('change', () => {
    startDate.value = '';
    endDate.value = '';
    refreshTotalCount();
  });
  departmentToggle?.addEventListener('change', () => {
    departmentCheckboxes.forEach((e) => (e.checked = false));
    refreshTotalCount();
  });
  dismissedDateToggle.addEventListener('change', () => {
    dismissedStartDate.value = '';
    dismissedEndDate.value = '';
    refreshTotalCount();
  });
  employmentStatusToggle.addEventListener('change', () => {
    employmentStatus.value = '';
    refreshTotalCount();
  });
  employmentTypeToggle.addEventListener('change', () => {
    employmentType.value = '';
    refreshTotalCount();
  });
  paymentMethodToggle.addEventListener('change', () => {
    paymentMethod.value = '';
    refreshTotalCount();
  });
  refreshTotalCount();
}

function selectDate() {
  const dateFilter = document.getElementById('report_date_filter');
  if (!dateFilter) {
    return;
  }
  let startDate = document.getElementById('report_start_date');
  let andText = document.getElementById('dateFilter');
  let endDate = document.getElementById('report_end_date');

  function toggleDate() {
    if (dateFilter.value === TIME_PERIOD_BETWEEN) {
      showElement(startDate);
      showElement(andText);
      showElement(endDate);
    } else if (dateFilter.value === TIME_PERIOD_AFTER) {
      endDate.value = '';
      hideElement(andText);
      hideElement(endDate);
      showElement(startDate);
    } else {
      startDate.value = '';
      hideElement(andText);
      hideElement(startDate);
      showElement(endDate);
    }
  }

  dateFilter.addEventListener('change', () => {
    toggleDate();
  });

  if ((startDate && endDate) || (!startDate && !endDate)) {
    dateFilter.value = TIME_PERIOD_BETWEEN;
  } else if (startDate) {
    dateFilter.value = TIME_PERIOD_AFTER;
  } else {
    dateFilter.value = TIME_PERIOD_BEFORE;
  }
  toggleDate();
}

function selectDismissedDate() {
  const dateFilter = document.getElementById('report_dismissed_date_filter');
  if (!dateFilter) {
    return;
  }
  let startDate = document.getElementById('report_dismissed_start_date');
  let andText = document.getElementById('dismissedDateFilter');
  let endDate = document.getElementById('report_dismissed_end_date');

  function toggleDate() {
    if (dateFilter.value === TIME_PERIOD_BETWEEN) {
      showElement(startDate);
      showElement(andText);
      showElement(endDate);
    } else if (dateFilter.value === TIME_PERIOD_AFTER) {
      endDate.value = '';
      hideElement(andText);
      hideElement(endDate);
      showElement(startDate);
    } else {
      startDate.value = '';
      hideElement(andText);
      hideElement(startDate);
      showElement(endDate);
    }
  }

  dateFilter.addEventListener('change', () => {
    toggleDate();
  });

  if ((startDate && endDate) || (!startDate && !endDate)) {
    dateFilter.value = TIME_PERIOD_BETWEEN;
  } else if (startDate) {
    dateFilter.value = TIME_PERIOD_AFTER;
  } else {
    dateFilter.value = TIME_PERIOD_BEFORE;
  }
  toggleDate();
}

document.addEventListener('DOMContentLoaded', () => {
  const reportsButton = document.getElementById('reports-form');
  if (reportsButton) {
    reportsButton.addEventListener('submit', handleGenerateReport);
  }

  selectPayrollColumns();
  selectEmployeeColumns();
  selectWorkAddressColumns();
  selectEmployerColumns();
  selectFilters();
  selectDate();
  selectDismissedDate();
});
