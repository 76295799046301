import { Modal } from 'bootstrap';

function cancelPayroll(evt) {
  evt.preventDefault();
  var form = document.getElementById(evt.target.dataset.onYesSubmitFormId);
  form.submit();
  var modalElement = evt.target.closest('.modal');
  Modal.getInstance(modalElement).hide();
}

document.addEventListener('DOMContentLoaded', () => {
  var cancelPayrollButton = document.getElementById('cancel-modal-button');
  if (cancelPayrollButton) {
    cancelPayrollButton.addEventListener('click', cancelPayroll);
  }
});
