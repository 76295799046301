document.addEventListener('DOMContentLoaded', () => {
  const percentageToggle = document.querySelectorAll('input[data-toggle-percentage]');

  const targetGroupTests = {};

  const togglePercentage = (targetInput, value = 'true') => {
    const sign = ['true', 'percentage'].includes(String(value)) ? '%' : '$';
    const position = '%' == sign ? 'afterend' : 'beforebegin';
    const groupTexts = targetGroupTests[targetInput.name];

    groupTexts.forEach((t) => t.remove());
    Array.from(groupTexts)
      .filter((t) => t.textContent == sign)
      .forEach((t) => {
        targetInput.insertAdjacentElement(position, t);
      });
  };

  // Show/hide percentage sign or $ depending on value
  percentageToggle.forEach((toggle) => {
    const targetName = toggle.dataset.togglePercentage;
    const targetInput = document.querySelector(`input[name="${targetName}"]`);

    if (!(targetName in targetGroupTests)) {
      targetGroupTests[targetName] = targetInput
        .closest('.input-group')
        .querySelectorAll('.input-group-text');
    }

    toggle.addEventListener('change', (e) => {
      togglePercentage(targetInput, e.target.value);
    });

    if (toggle.checked) {
      togglePercentage(targetInput, toggle.value);
    }
  });
});
